import React from 'react'
import styled from 'react-emotion'
import { One, OneThird, Row, RowGroup, Three } from '../../components/Grid'
import SEO from '../../components/SEO'
import { H1, H2, H3, H4 } from '../../components/Style'
import { FONT_SMALL } from '../../constants'
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/courses",
      "name": "Courses"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/courses/react-redux",
      "name": "React with Redux"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <SEO
        path="/courses/react-redux"
        title="React with Redux course by 500Tech"
        description="This course covers all the practical aspects of developing applications with React as a UI layer and Redux as a data management and server communication layers."
        ld={breadcrumbs}
      />

      <Row>
        <H1>React and Redux Course</H1>

        <Three>
          This course covers all the practical aspects of developing
          applications with React as a UI layer and Redux as a data management
          and server communication layers. By the end of the course, students
          will be able to join existing React projects and start developing
          features.
        </Three>
      </Row>

      <RowGroup>
        <H2>Standard Syllabus</H2>
        <Row>
          <Three>
            This hands-on training consists of eight modules that cover both
            basic concepts as well as most of the common pain points engineers
            face daily when developing single-page applications with React and
            Redux.
          </Three>
        </Row>

        <SmallText>
          <Row>
            <OneThird>
              <H4>I. Core Concepts</H4>
              <p>
                A walkthrough over React’s concepts and its approach for modern
                front- end development. Gaining a solid understanding of what is
                React and how it works with single-page apps.
              </p>

              <List>
                <ListItem>
                  High-level React: Virtual DOM, change detection, difference
                  from other frameworks
                </ListItem>
                <ListItem>
                  Shifting mindset from MVC to component architecture
                </ListItem>
                <ListItem>Ecosystem and popular libraries</ListItem>
              </List>
            </OneThird>
            <OneThird>
              <H4>II. Modern JavaScript and JSX</H4>
              <p>
                Acquiring knowledge and practical usage of modern JavaScript
                APIs and patterns used daily with React. Understand the
                advantage of JSX over templating engines.
              </p>

              <List>
                <ListItem>
                  JavaScript refresher: module system, classes, scoping, new
                  operators, destructuring, etc
                </ListItem>
                <ListItem>
                  Leveraging new language features to write cleaner code
                </ListItem>
                <ListItem>
                  Understanding JSX: how it works, difference from templating
                  engines, and its restrictions
                </ListItem>
              </List>
            </OneThird>
            <OneThird>
              <H4>III. Component Basics</H4>
              <p>
                Gaining solid understanding of React components. Getting
                familiar with terminology, learning different ways to create
                components. Understanding the render flow and lifecycle.
              </p>

              <List>
                <ListItem>
                  Props and State; difference between stateful and stateless
                  components; PropTypes; Glimpse into useState hook
                </ListItem>
                <ListItem>
                  Component lifecycle methods, refs, event and input handling,
                  conditional rendering; Glimpse into useEffect hook
                </ListItem>
                <ListItem>
                  Controlled, uncontrolled, and presentational components
                </ListItem>
              </List>
            </OneThird>
          </Row>

          <Row>
            <OneThird>
              <H4>IV. Introduction to Redux</H4>
              <p>
                Understanding what Redux is and why we should use it. Learning
                how to bring Redux into existing projects and how structure new
                ones.
              </p>

              <List>
                <ListItem>
                  Redux core concepts: single source of truth, unidirectional
                  data flow, and immutability.
                </ListItem>
                <ListItem>
                  React and Redux: typical directory structure, connecting to
                  store
                </ListItem>
                <ListItem>
                  Basic Redux parts: store, actions, reducers, and middleware
                </ListItem>
              </List>
            </OneThird>
            <OneThird>
              <H4>V. React and Redux Ecosystem</H4>
              <p>
                Getting familiar with some popular 3rd-party libraries required
                for most single-page applications.
              </p>

              <List>
                <ListItem>
                  Common approaches to styling: CSS-in-JS, styled components,
                  plain old CSS
                </ListItem>
                <ListItem>
                  Routing with React and Redux, navigation, implementing layouts
                </ListItem>
                <ListItem>
                  Strategies, practices, and tools for handling immutable data
                </ListItem>
              </List>
            </OneThird>
            <OneThird>
              <H4>VI. Testing and Debugging</H4>
              <p>
                Reasoning about testing different parts of React/Redux
                applications. Effective debugging of unidirectional flow and
                complex UI trees.
              </p>

              <List>
                <ListItem>
                  Testing React components: snapshots and DOM, integration,
                  end-to-end, visual, and manual
                </ListItem>
                <ListItem>
                  Testing logic: actions, reducers, selectors, and middleware
                </ListItem>
                <ListItem>
                  Debugging: general approaches as well as tools and extensions
                </ListItem>
              </List>
            </OneThird>
          </Row>
        </SmallText>
      </RowGroup>

      <Row>
        <Three>
          During the course, the students will be practicing taught material on
          their laptops and will be able to ask questions related to their
          current projects.
        </Three>
      </Row>

      <RowGroup>
        <H2>Additional Modules</H2>
        <Row>
          <Three>
            In addition to basic course modules, you can extend the course to
            include more advanced modules or materials that would be relevant
            specifically to your organization. Below are some of the proposed
            modules.
          </Three>
        </Row>

        <Row>
          <One>
            <SmallText>
              <H4>Advanced Components</H4>
              <p>
                Taking advantage of React’s powerful features for better and
                cleaner components. Advanced patterns for building reusable and
                extensible components.
              </p>

              <List>
                <ListItem>
                  Composition patterns: higher- order components; render props;
                  React.Children
                </ListItem>
                <ListItem>
                  Glimpse into writing custom hooks for encapsulating and reusing behaviour
                </ListItem>
                <ListItem>
                  Useful React APIs: fragments, pure components, context, and
                  portals
                </ListItem>
                <ListItem>
                  Performance considerations and optimizations, lazy loading components
                </ListItem>
              </List>
            </SmallText>
          </One>
          <One>
            <SmallText>
              <H4>Practical React</H4>
              <p>
                Diving deeper into popular 3rd-party libraries addressing common
                pain points in single-page applications.
              </p>

              <List>
                <ListItem>
                  Different approaches to styling, forms, and routing
                </ListItem>
                <ListItem>
                  Solving business problems for a11y, i18n, and SEO
                </ListItem>
                <ListItem>
                  Choosing the right libraries and learning resources
                </ListItem>
              </List>
            </SmallText>
          </One>
          <One>
            <SmallText>
              <H4>Advanced Redux</H4>
              <p>
                Leveraging Redux middleware and other tools to support complex
                flows. Understand the importance of best practices and good
                conventions.
              </p>

              <List>
                <ListItem>
                  Advanced middleware and action processing patterns and
                  practices
                </ListItem>
                <ListItem>
                  State management: modeling, normalization, advanced selectors,
                  and keeping it clean
                </ListItem>
                <ListItem>
                  Store and reducer enhancers, approaches to cleaner reducers
                </ListItem>
              </List>
            </SmallText>
          </One>
          <One>
            <H3>Custom Materials</H3>
            <p>
              We can customize course materials and build modules not
              represented in this syllabus based on specific needs of your
              company, product, or engineers.
            </p>
          </One>
        </Row>
      </RowGroup>

      <ContactForm />
    </Layout>
  )
}

const SmallText = styled.div`
  font: ${FONT_SMALL};
`

const List = styled.ul``

const ListItem = styled.li`
  padding-left: 20px;
  margin-bottom: 5px;

  &:before {
    content: '✓';
    margin-right: 10px;
    margin-left: -20px;
  }
`
